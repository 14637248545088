<template>
  <div>
    <h1>German Musiktitel DB Search</h1>
    <form @submit.prevent="getMusiktiteldbUrl">
      <label>Title <input type="text" v-model.trim="title" /></label>
      <label
        >Artist (Last Name, First Name)
        <input type="text" v-model.trim="artist"
      /></label>
      <label>Exact keyword <input type="text" v-model.trim="exact" /></label>
      <input type="submit" value="Search" />
      <button @click="clearAllSearchTerms">Clear all</button>
    </form>
    <section v-if="outputUrl">
      <ol>
        <li>
          <a :href="outputUrl" ref="url">{{
            `Search for ${artist} ${title} ${exact} on German Musiktitel DB`
          }}</a>
        </li>
        <li>
          <a href="http://www.musiktiteldb.de/JahrgangSingles.html"
            >All cylinder, shellac singles by year - 1890 to 1990</a
          >
        </li>
        <li>
          <a href="http://www.musiktiteldb.de/IndexJahrgaenge.html"
            >All music titles by year - 1890 to 2021</a
          >
        </li>
      </ol>
    </section>
  </div>
</template>


<script>
export default {
  data() {
    return {
      title: "",
      artist: "",
      exact: "",
      outputUrl: "",
    };
  },
  methods: {
    clearAllSearchTerms() {
      this.title = "";
      this.artist = "";
      this.exact = "";
      this.outputUrl = "";
    },
    getMusiktiteldbUrl() {
      this.outputUrl = `http://musiktiteldb.de/htdocs/anw-musik/FormMusikGesamt.php?aktion=suchen&suchbegriff=${this.artist}&aktion=suchen&Titel=${this.title}&aktion=suchen&suchbegriffexakt=${this.exact}`;

      this.$nextTick(() => {
        let el = this.$refs.url;
        el.focus();
      });
    },
  },
};
</script>
